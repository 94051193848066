import { TrashIcon } from '@heroicons/react/24/outline';

import {
  Sticker,
  NumberStepper,
  StockIndicators,
  Price,
  ImageMissing,
  Alert,
} from '@fagmobler/ui';
import type { OmniumStore, OmniumOrderLine } from '@fagmobler/omnium';
import type {
  FagmoblerPublicOmniumProduct,
  FagmoblerAlgoliaProduct,
  FagmoblerInventory,
} from '@fagmobler/types';

import {
  convertLineitemIntoEcommerceObject,
  trackEvent,
} from '@/lib/analytics';

import Image from 'next/image';
import { useUpdateCartItem } from './useUpdateCartItem';
import { useRemoveItemFromCart } from './useRemoveItemFromCart';
import { useCart } from './useCart';
import { missingImageUrls } from '@/lib/utils/missingImageUrls';
import clsx from 'clsx';
import Link from 'next/link';
import { useNavigation } from '@/contexts/navigation';
import { useEffect, useState } from 'react';
import { ALGOLIA_INDEX_NAME, searchClient } from '@/lib/algolia/searchClient';
import { getCanonicalPath } from '@/lib/algolia/getCanonicalPath';

export type CartItemProps = {
  item: OmniumOrderLine & { description?: string };
  product?: FagmoblerPublicOmniumProduct;
  store?: OmniumStore | null;
  inventory?: FagmoblerInventory;
};

export function CartItem({
  item = {},
  product,
  store,
  inventory,
}: CartItemProps) {
  const { cart } = useCart();
  const { setShowCart, setShowStores } = useNavigation();
  const [url, setUrl] = useState<string | null>(null);
  const [algoliaProduct, setAlgoliaProduct] =
    useState<FagmoblerAlgoliaProduct | null>(null);

  useEffect(() => {
    if (product?.skuId) {
      const index = searchClient.initIndex(ALGOLIA_INDEX_NAME);
      index.getObject(product.skuId.toUpperCase()).then((object) => {
        setAlgoliaProduct(object);
        setUrl(getCanonicalPath(object));
      });
    }
  }, [product?.skuId]);

  const isBackorder = product?.isBackorder || false;

  const isVividworksCart = cart.data?.salesChannel === 'API';

  const updateCartItem = useUpdateCartItem();
  const removeCartItem = useRemoveItemFromCart();

  const {
    lineItemId,
    displayName,
    imageUrl,
    placedPrice = 0,
    extendedPrice = 0,
    discountedPrice = 0,
    quantity = 0,
    orderLineDiscounts,
    isPackage,
  } = item;

  const description =
    item.description ||
    product?.properties.find((p) => p.key === 'posterDescription')?.value;

  const hasImage = imageUrl && !missingImageUrls.includes(imageUrl);

  const stock =
    (product?.skuId && store?.id && inventory?.[product.skuId]?.[store.id]) ||
    undefined;
  const href = url || `/rom/${product?.skuId}`;

  const inStock = stock ? stock.stock > 0 : false;
  const canBeOrdered = !(isBackorder && !inStock);

  return (
    <li
      key={lineItemId}
      className={clsx('mb-4 md:mb-6', {
        'p-4 bg-secondary-20 border border-neutral-50 rounded': !canBeOrdered,
      })}
    >
      {!canBeOrdered && (
        <Alert type="warning" className="mb-4" interactive={true}>
          <span className="block w-full flex justify-between gap-1">
            <span>Du har valgt en butikk som ikke har varene på lager.</span>
            <button
              className="underline font-bold"
              onClick={() => {
                setShowStores(true);
              }}
            >
              Endre butikk
            </button>
          </span>
        </Alert>
      )}
      <section className="flex items-start">
        <Link
          className="aspect-square bg-neutral-30 rounded relative mr-4 flex justify-center items-center"
          href={href}
          onClick={() => setShowCart(false)}
        >
          {hasImage ? (
            <Image
              className={clsx('mix-blend-darken object-contain', {
                'p-1': isPackage,
                'p-4': !isPackage,
              })}
              alt={item.displayName ? `Bilde av ${item.displayName}` : ''}
              src={imageUrl}
              width={96}
              height={96}
            />
          ) : (
            <div className="w-24 h-24">
              <ImageMissing size="sm" />
            </div>
          )}
        </Link>

        <div>
          <Link href={href} onClick={() => setShowCart(false)}>
            <h3 className="hd-base">{displayName}</h3>
          </Link>
          {description && <p className="body-xs">{description}</p>}

          <footer>
            {!isPackage && (
              <div className="mt-4 mb-2 pr-4">
                {!store || !store?.id ? (
                  <Sticker size="sm">Velg butikk for å se lagerstatus</Sticker>
                ) : (
                  <StockIndicators
                    stock={stock}
                    cartQuanitity={quantity}
                    notAvailable={isBackorder}
                    isBackorder={isBackorder}
                    deliveryTime={algoliaProduct?.deliveryTimeText}
                    display="cart"
                  />
                )}
              </div>
            )}
            {isPackage ? (
              <Price
                withStickers={false}
                price={placedPrice}
                memberPrice={
                  extendedPrice < discountedPrice ? discountedPrice : undefined
                  // @todo this is a hack to make sure it only applies to vividworks line items.
                  // I don't see why we can't always use the `discountedPrice` attribute, but
                  // I didn't want to risk breaking something.
                  // isVividworksCart
                  //   ? undefined
                  //   : product?.price?.unitPrice
                  //   ? product?.price.unitPrice * quantity
                  //   : discountedPrice
                }
              />
            ) : (
              <Price
                withStickers={false}
                price={placedPrice}
                campaignPrice={
                  discountedPrice < placedPrice * quantity
                    ? discountedPrice / quantity
                    : undefined
                }
                memberPrice={
                  orderLineDiscounts?.find(
                    (discount) => discount.discountId === 'Member'
                  ) && placedPrice !== discountedPrice / quantity
                    ? discountedPrice / quantity
                    : undefined
                }
              />
            )}

            <div>
              {!isPackage && (
                <NumberStepper
                  onDecrement={async () => {
                    if (quantity === 1) {
                      trackEvent(
                        'remove_from_cart',
                        convertLineitemIntoEcommerceObject({
                          lineItem: item,
                          omniumProduct:
                            product as FagmoblerPublicOmniumProduct,
                          algoliaProduct: {} as FagmoblerAlgoliaProduct,
                        }),
                        store || undefined
                      );
                      await removeCartItem.mutateAsync(item);
                      return;
                    }
                    trackEvent(
                      'remove_from_cart',
                      convertLineitemIntoEcommerceObject({
                        lineItem: item,
                        omniumProduct: product as FagmoblerPublicOmniumProduct,
                        algoliaProduct: {} as FagmoblerAlgoliaProduct,
                      }),
                      store || undefined
                    );
                    await updateCartItem.mutateAsync({
                      ...item,
                      quantity: quantity - 1,
                    });
                  }}
                  onIncrement={async () => {
                    trackEvent(
                      'add_to_cart',
                      convertLineitemIntoEcommerceObject({
                        lineItem: item,
                        omniumProduct: product as FagmoblerPublicOmniumProduct,
                        algoliaProduct: {} as FagmoblerAlgoliaProduct,
                      }),
                      store || undefined
                    );
                    await updateCartItem.mutateAsync({
                      ...item,
                      quantity: quantity + 1,
                    });
                  }}
                  value={quantity}
                />
              )}

              <button
                onClick={async () => {
                  trackEvent(
                    'remove_from_cart',
                    convertLineitemIntoEcommerceObject({
                      lineItem: item,
                      omniumProduct: product as FagmoblerPublicOmniumProduct,
                      algoliaProduct: {} as FagmoblerAlgoliaProduct,
                    }),
                    store || undefined
                  );
                  await removeCartItem.mutateAsync(item);
                }}
                className="text-text-danger"
              >
                <TrashIcon className="h-4" />
              </button>
            </div>
          </footer>
        </div>
      </section>
    </li>
  );
}

export default CartItem;
